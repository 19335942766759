import RedmiImg from "@/img/localiser-un-redmi.jpg";
import { Box, Button, Stack, Typography } from "@mui/material";
import { useOutletContext } from "react-router-dom";

type ContextType = {
  searchForm: JSX.Element;
};

function LocaliserSonRedmi() {
  const { searchForm } = useOutletContext<ContextType>();

  return (
    <Stack gap={6}>
      <Stack gap={3}>
        <Typography variant="h2">Localiser un Redmi</Typography>
        <Typography>
          Si vous souhaitez localiser un téléphone Redmi volé ou perdu,
          différentes possibilités s'offrent à vous. Ici, nous vous proposons
          plusieurs solutions pour retrouver votre appareil mobile à travers des
          méthodes simples et accessibles à tous !
        </Typography>
        {searchForm}
        <Box component="img" src={RedmiImg} />
      </Stack>

      <Stack gap={3}>
        <Typography variant="h2">
          Comment localiser un portable Redmi en cas de perte ou de vol ?
        </Typography>
        <Typography>
          Aujourd'hui, la géolocalisation de votre téléphone Redmi est
          relativement facile. Pour retrouver votre smartphone en cas de vol ou
          de perte, il vous suffit d'avoir recours à l'une des méthodes
          ci-dessous.
        </Typography>

        <Typography variant="h3">
          Localiser son téléphone avec geolocalisation-mobile.com
        </Typography>
        <Typography>
          En premier lieu, le service geolocalisationtion-mobile.com apparaît
          comme l'une des solutions les plus efficaces pour identifier
          rapidement la localisation de votre smartphone. Celui-ci est
          performant aussi bien pour les cas de vol ou de perte. Il s'agit, en
          effet, d'un service en ligne doté d'une interface ergonomique et très
          fluide. Pour rappel, le site prend en compte tous les systèmes
          d'exploitation mobiles.
        </Typography>
        <Typography>
          Si la plateforme est très appréciée, c'est notamment grâce à sa
          simplicité d'utilisation. Compatible avec tous les types de modèles,
          d'opérateurs et de marques, nous vous offrons la possibilité de
          retrouver la position exacte de votre portable. Il vous suffit tout
          simplement de vous rendre directement sur notre écran d'accueil.
        </Typography>

        <Typography variant="h3">
          Localiser son téléphone avec « Xiaomi Cloud »
        </Typography>
        <Typography>
          Une autre manière de retrouver votre Redmi est de passer par Xiaomi
          Cloud. C'est un service de la marque qui a été conçu pour vous aider à
          retrouver votre smartphone en cas de perte ou de vol. Pour cela, vous
          devez vous connecter sur i.mi.com afin d'accéder à votre espace
          (Mi-cloud) et lancer la procédure de localisation. Cependant, pour que
          la position vous soit donnée, il faudra activer au préalable la
          fonction 'Localiser mon appareil' dans les paramètres du téléphone. Si
          vous ne l'aviez pas fait avant le vol de l'appareil, vous ne pourrez
          donc pas le retrouver.
        </Typography>

        <Typography variant="h3">
          Localiser son téléphone avec Google Find My Service
        </Typography>
        <Typography>
          Il est également possible de passer par le géant Américain Google pour
          localiser votre Redmi perdu ou volé. Pour ce faire, vous devez
          utiliser Google Find My Service. Pour utiliser ce service propre aux
          téléphones Android, vous devez commencer par télécharger l'application
          sur Play Store. Vous pouvez aussi vous connecter directement à travers
          le lien android.com.find.
        </Typography>
        <Typography>
          Vous devez ensuite choisir l'appareil que vous souhaitez retrouver.
          Notez toutefois que cette option n'est pas optimale à 100% car elle
          vous donnera une localisation approximative et non pas l'emplacement
          exact de votre smartphone.
        </Typography>
      </Stack>

      <Stack gap={3}>
        <Typography variant="h2">
          Comment fonctionne geolocalisation-mobile.com pour localiser son Redmi
          ?
        </Typography>
        <Typography>
          Vous pouvez retrouver votre téléphone Redmi en seulement quelques
          clics avec cette solution. Commencez bien évidemment par vous rendre
          sur la page d'accueil. Il ne vous restera qu'à renseigner le numéro de
          l'appareil que vous souhaitez géolocaliser puis effectuer le paiement.
          Il est important de signaler que toutes vos données sont hautement
          sécurisées grâce à notre système de cryptage.
        </Typography>
        <Typography>
          Une fois le paiement effectué, le destinataire reçoit alors une alerte
          sur son écran pour accorder le partage de position. S'il accepte, vous
          recevrez un SMS ou un e-mail qui vous donnera l'emplacement exact de
          votre smartphone Redmi.
        </Typography>
      </Stack>

      <Stack gap={3}>
        <Typography variant="h2">
          Questions à propos de la localisation d'un Redmi
        </Typography>
        <Typography>
          Dans cette section, nous répondons aux principales interrogations que
          certains utilisateurs des téléphones Redmi peuvent se poser.
        </Typography>

        <Typography variant="h3">
          Peut-on géolocaliser un Redmi avec IMEI ?
        </Typography>
        <Typography>
          Il est possible de localiser votre Redmi avec IMEI. Il s'agit en effet
          d'un long numéro qui correspond à l'identifiant de votre appareil.
          Pour l'obtenir, il vous suffit de taper *#06#. Il est également
          possible de le trouver facilement à l'arrière de votre batterie. Pour
          localiser le téléphone, vous devez tout simplement contacter votre
          opérateur. Donnez-lui ce numéro de série et il vous indiquera
          approximativement l'emplacement de votre appareil.
        </Typography>

        <Typography variant="h3">
          Peut-on géolocaliser un Redmi gratuitement ?
        </Typography>
        <Typography>
          Oui, il est possible de localiser votre Redmi gratuitement. Il vous
          suffit simplement de choisir parmi l'une des options proposées plus
          haut. Notez cependant que les solutions gratuites ne fournissent pas
          les informations les plus précises. Pour une localisation la plus
          fiable possible, il vaut mieux faire confiance à
          geolocalisation-mobile.com, même si les services sont payants.
        </Typography>

        <Typography variant="h3">
          Mon Redmi est éteint ou n'a plus de batterie, puis-je tout de même le
          localiser ?
        </Typography>
        <Typography>
          Il est impossible de localiser votre Redmi si celui-ci est éteint.
          Autrement dit, l'appareil doit impérativement être allumé pour
          profiter de ce service. Si vous essayez, vous n'obtiendrez que la
          dernière localisation du téléphone lorsqu'il était en marche.
        </Typography>

        <Typography variant="h3">
          L'option de géolocalisation n'est pas activée sur mon Redmi, la
          localisation est-elle possible ?
        </Typography>
        <Typography>
          La localisation de votre Redmi n'est envisageable que si vous avez
          activé l'option de localisation dans vos paramètres. C'est pour cette
          raison que nous vous recommandons de garder ce mode activé.
        </Typography>
      </Stack>

      <Stack gap={3}>
        <Typography variant="h2">
          Que faire en cas de vol de votre Redmi ?
        </Typography>
        <Typography>
          En cas de vol de votre Redmi, vous pouvez essayer de le localiser en
          passant par les fonctionnalités Google Find My Device ou Xiaomi Cloud.
          Toutefois, comme cela a été précisé, la plateforme
          geolocalisation-mobile.com est l'option la plus efficace. Celle-ci
          vous garantit d'obtenir les coordonnées exactes du téléphone. Par
          ailleurs, vous pouvez également choisir de contacter votre opérateur
          afin que ce dernier suspende votre ligne. De cette manière, la
          personne qui détient votre téléphone ne pourra plus s'en servir.
        </Typography>
        <Typography>
          Dans le cas d'un vol, il est également conseillé d'aller déposer une
          plainte à la gendarmerie ou au commissariat. Lorsque vous y allez,
          vous devez fournir le code IMEI de votre Redmi. Les forces de l'ordre
          vous demanderont de transmettre d'autres informations concernant les
          caractéristiques de l'appareil.
        </Typography>
        <Typography>
          Vous pouvez aussi vous connecter sur la plateforme en ligne du
          fabricant du téléphone pour effacer les données de votre téléphone à
          distance. Cela vous aidera à protéger votre vie privée.
        </Typography>
      </Stack>

      <Stack gap={3}>
        <Typography variant="h2">
          Les modèles de téléphones Redmi les plus populaires qu'on peut
          localiser
        </Typography>
        <Typography>
          Il faut savoir qu'il existe des smartphones Redmi réputés comme
          populaires pour la géolocalisation en cas de perte ou de vol. Si vous
          avez l'un de ces modèles, vous pouvez facilement les localiser avec
          l'une des méthodes mentionnées.
        </Typography>

        <Typography variant="h3">Le Redmi Note 11</Typography>
        <Typography>
          Avec son écran de 6.43 pouces, le Redmi Note 11 est un appareil de la
          gamme Xiaomi très puissant. Il est équipé d'un capteur d'empreintes
          ainsi que de deux caméras (avant et arrière). C'est grâce à son GPS L1
          qu'il est facilement localisable.
        </Typography>

        <Typography variant="h3">Le Redmi Note 10 Pro</Typography>
        <Typography>
          C'est également un mobile que vous pouvez facilement retrouver en cas
          de perte ou de vol. Doté d'un écran OLED de 6,67 pouces cet appareil
          dispose de la technologie NFC qui facilite l'échange de données. Il
          offre aussi un très bon système de sécurité à travers la
          reconnaissance faciale. Vous avez la possibilité de le localiser à
          partir de Galileo E1.
        </Typography>
      </Stack>
      <Stack sx={{ alignItems: "center" }}>
        <Button variant="contained" href="/" color="error">
          DEMARRER LA GEOLOCALISATION
        </Button>
      </Stack>
    </Stack>
  );
}

export default LocaliserSonRedmi;
