import LeSacheImg from "@/img/localiser-telephone-sans-que-la-personne-le-sache.jpg";
import { Box, Button, Stack, Typography } from "@mui/material";
import { useOutletContext } from "react-router-dom";

type ContextType = {
  searchForm: JSX.Element;
};

function LocaliserUnTelephoneSansQueLaPersonneLeSache() {
  const { searchForm } = useOutletContext<ContextType>();

  return (
    <Stack gap={6}>
      <Stack gap={3}>
        <Typography variant="h2">
          Localiser un téléphone sans que la personne le sache
        </Typography>
        <Typography>
          Parmi toutes les avancées technologiques, il y a la possibilité de
          localiser les appareils mobiles. Cette fonctionnalité récemment
          popularisée permet de retrouver une personne grâce à son numéro de
          téléphone. Désormais, les fabricants de smartphones proposent des
          logiciels permettant la géolocalisation sans que la personne le sache.
          Voici tout ce que vous devez savoir !
        </Typography>
        {searchForm}
        <Box component="img" src={LeSacheImg} />
      </Stack>

      <Stack gap={3}>
        <Typography variant="h2">
          Peut-on localiser un téléphone sans que la personne le sache ?
        </Typography>
        <Typography>
          Avec l'évolution du secteur numérique, il est désormais possible de
          localiser une personne à distance. Il est parfois possible de le faire
          sans que le propriétaire du téléphone ne le sache. Vous pouvez
          utiliser des solutions payantes ou gratuites pour localiser un
          appareil mobile à distance.
        </Typography>
        <Typography>
          Il existe de nombreuses techniques pour exécuter cette fonctionnalité.
          Certaines méthodes nécessitent la présence de l'appareil cible lorsque
          le logiciel est installé. D'autres méthodes par contre ne requièrent
          pas de contact physique avec le portable. Il suffit que celui-ci soit
          constamment connecté à Internet.
        </Typography>
        <Typography>
          Par ailleurs, de nombreuses raisons peuvent amener une personne à
          vouloir localiser le téléphone d'une autre sans que cette dernière ne
          le sache.
        </Typography>
      </Stack>

      <Stack gap={3}>
        <Typography variant="h2">
          Pourquoi localiser un téléphone sans que la personne le sache ?
        </Typography>
        <Typography>
          Il existe plusieurs raisons qui incitent quelqu'un à localiser un
          téléphone à l'insu de son propriétaire. Il peut s'agir d'un parent qui
          désire rester informé des allées et venues de son enfant. Dans ce cas,
          une application de localisation est utilisée par le parent soucieux de
          la sécurité de sa progéniture. Par exemple, cette fonctionnalité vous
          permet de savoir où se trouve votre enfant après l'école. Notez que la
          surveillance en ligne est parfaitement légale en vertu de la loi dans
          le cadre du contrôle parental.
        </Typography>
        <Typography>
          De plus, la localisation d'un appareil téléphonique peut être une
          solution d'assistance à distance pour les personnes âgées. Grâce à
          cette fonctionnalité, les parents seniors vivant seuls à la maison
          peuvent obtenir de l'aide de n'importe où. Si ce dernier souffre d'une
          maladie neurodégénérative, la géolocalisation peut être très
          nécessaire s'il s'égare de son domicile. Les chefs d'entreprise
          souhaitant surveiller les activités menées par leurs employés via
          leurs téléphones portables professionnels peuvent également recourir à
          ce service.
        </Typography>
        <Typography>
          Même les conjoints qui ne font pas confiance à la loyauté de leur
          partenaire peuvent y trouver leur compte. Pour répondre à cette
          demande, les concepteurs des téléphones mobiles ont développé des
          logiciels parfois disponibles gratuitement.
        </Typography>
      </Stack>

      <Stack gap={3}>
        <Typography variant="h2">
          Les applications pour localiser un téléphone sans que la personne le
          sache
        </Typography>
        <Typography>
          Les systèmes d'exploitation mobiles développés par les principaux
          fabricants de smartphones ont chacun un moyen de localisation.
          Ci-dessous, les moyens de localiser un smartphone sans que le
          propriétaire le sache sont détaillés. Il est possible de le faire sur
          Google Android, Apple et mSpy.
        </Typography>

        <Typography variant="h3">Find my device sur Android</Typography>
        <Typography>
          Les utilisateurs d'Android peuvent utiliser la fonction intégrée «
          Localiser mon appareil ». Pour activer cette fonctionnalité, vous
          devez consulter le lien sur Google à partir de votre navigateur Web.
          Pour trouver le téléphone, il faut vous connecter avec l'e-mail et le
          mot de passe de votre compte.
        </Typography>
        <Typography>
          Il vous faut ensuite sélectionner l'appareil mobile et activez la
          fonction « Localiser mon appareil ». L'emplacement sera affiché sur
          une carte détaillée. Cette action ne peut être réalisée que si le
          portable est connecté à Internet.
        </Typography>

        <Typography variant="h3">Find my iPhone sur iOS</Typography>
        <Typography>
          Apple a aussi développé un logiciel gratuit pour la localisation d'un
          iPhone. Ce paramètre peut être exécuté à l'insu du propriétaire de
          l'appareil. Cette fonction intégrée est appelée « Find my iPhone ».
          Celle-ci peut également servir à retrouver un portable Apple égaré.
        </Typography>
        <Typography>
          Pour utiliser cette fonctionnalité, vous devez d'abord connaître
          l'identifiant Apple du téléphone cible. Une fois que vous l'avez, tout
          ce que vous avez à faire est d'aller dans l'espace iCloud. Quand vous
          êtes dans l'espace ci-dessus, sélectionnez la section « Appareils ».
          Localisez ensuite l'appareil surveillé sur la carte, que vous pouvez
          trouver dans l'onglet « Cartes ». Si le smartphone à localiser est
          déchargé ou éteint, vous ne pouvez accéder qu'à sa dernière
          localisation.
        </Typography>

        <Typography variant="h3">mSpy</Typography>
        <Typography>
          mSpy est un logiciel de contrôle parental en ligne spécialement conçu
          pour être installé sur les téléphones mobiles. Si vous disposez d'un
          numéro : mSpy, vous pouvez suivre l'emplacement en temps réel du
          téléphone. Vous pouvez ainsi géolocaliser vos enfants sans qu'ils
          soient informés.
        </Typography>
        <Typography>
          De cette façon, vous pourrez grâce à une carte surveiller et contrôler
          les déplacements de la personne concernée. Il est également possible
          d'afficher l'historique mobile de votre téléphone au fil du temps.
          Enfin, vous avez accès en temps réel à toutes les données depuis votre
          panneau de contrôle personnel.
        </Typography>

        <Typography variant="h3">
          Localiser un téléphone avec geolocalisation-mobile.com
        </Typography>
        <Typography>
          Geolocalisation-mobile.com est un service qui vous aide à retrouver
          vos proches grâce à votre téléphone mobile. Pour ce faire, la
          plateforme propose une gamme de services accessible depuis votre
          smartphone. Cela vous permet de localiser un iPhone ou un téléphone
          Android quel que soit l'opérateur. Geolocalisation-mobile.com dispose
          également d'algorithmes qui vous aident à localiser votre téléphone
          partout dans le monde.
        </Typography>
        <Typography>
          De plus, cette application n'enregistre aucune donnée utilisateur
          associée à votre téléphone mobile. Elle vous garantit ainsi votre
          confidentialité. Le suivi se fait sur leurs serveurs et leur système
          gère toutes les informations. À la fin du processus, vous recevrez les
          coordonnées et la position GPS du téléphone.
        </Typography>
        <Typography>
          Les services fournis sont efficaces et faciles à utiliser. Étant donné
          que la plate-forme utilise des données GPS, vous pouvez être sûr
          qu'elles sont très précises. Vous obtiendrez donc l'emplacement exact
          du numéro de téléphone de localisation.
        </Typography>

        <Typography variant="h3">
          Comment fonctionne geolocalisation-mobile.com pour localiser un
          téléphone ?
        </Typography>
        <Typography>
          L'utilisation de geolocalisation-mobile.com est très simple. Tout ce
          que vous avez à faire est de préciser le numéro de téléphone que vous
          souhaitez rechercher. Ensuite, il vous faut souscrire au service.
          Notez que le paiement est totalement sécurisé.
        </Typography>
        <Typography>
          Le destinataire reçoit une notification qui lui demande d'accepter de
          partager sa position. Après cela, vous recevrez la position du mobile
          par courriel et aussi par SMS. En revanche, la personne à localiser
          doit être connectée à Internet pour permettre le suivi du téléphone
          portable cible. Pour finir, l'algorithme du logiciel va alors placer
          le script sur l'appareil cible. Ce dernier permet au système de
          Geolocalisation-mobile.com de récupérer les coordonnées exactes d'une
          personne et de vous les montrer sur une carte.
        </Typography>
      </Stack>
      <Stack sx={{ alignItems: "center" }}>
        <Button variant="contained" href="/" color="error">
          DEMARRER LA GEOLOCALISATION
        </Button>
      </Stack>
    </Stack>
  );
}

export default LocaliserUnTelephoneSansQueLaPersonneLeSache;
