import EteintImg from "@/img/localiser-telephone-vole-eteint.jpg";
import { Box, Button, Stack, Typography } from "@mui/material";
import { useOutletContext } from "react-router-dom";

type ContextType = {
  searchForm: JSX.Element;
};

function LocaliserTelephoneVoleEteint() {
  const { searchForm } = useOutletContext<ContextType>();

  return (
    <Stack gap={6}>
      <Stack gap={3}>
        <Typography variant="h2">Localiser téléphone volé éteint</Typography>
        <Typography>
          Les téléphones portables peuvent être facilement géolocalisés de nos
          jours s'ils sont égarés ou perdus. Il est même possible de les
          localiser lorsqu'ils sont éteints. Il existe en effet aujourd'hui,
          plusieurs outils qui permettent aux utilisateurs de smartphone de
          connaître la position géographique de leurs mobiles à tout instant.
          Certaines fonctionnalités sont intégrées aux systèmes d'exploitation
          des téléphones, tandis que d'autres sont des applications ou des
          plateformes web. Découvrez ici comment localiser un téléphone volé
          lorsqu'il est éteint.
        </Typography>
        {searchForm}
        <Box component="img" src={EteintImg} />
      </Stack>

      <Stack gap={3}>
        <Typography variant="h2">
          Comment localiser un téléphone volé éteint ?
        </Typography>
        <Typography>
          Que ce soit un Samsung, un iPhone ou autre, vous pouvez localiser
          votre téléphone portable s'il est volé éteint. En réalité les
          appareils Apple comme Android, ont tous une fonctionnalité intégrée
          permettant aux utilisateurs de les retrouver lorsqu'ils les ont égarés
          ou perdu. Ceci même si les smartphones sont éteints.
        </Typography>
        <Typography>
          Vous pouvez donc utiliser la fonction "Find my Device" sur les
          appareils Android pour les retrouver. Vous devez cependant au
          préalable activer cette fonctionnalité dans les paramètres avant que
          la localisation de votre mobile pour que la localisation soit
          possible.
        </Typography>
        <Typography>
          Pour les appareils d'Apple, il s'agit d'une fonctionnalité appelée
          "localiser mon iPhone" et "Envoyer la dernière position connue". Dès
          que cela est activé, vous pourrez connaître l'emplacement précis ou du
          moins la dernière localisation de votre mobile.
        </Typography>
        <Typography>
          Vous pouvez par exemple utiliser le site geolocalisation-mobile.com
          pour retrouver votre téléphone. Si le mobile est allumé et connecté à
          un réseau mobile ou Wi-Fi, vous aurez une localisation géographique en
          temps réel de l'appareil. S'il est éteint, vous aurez le dernier
          emplacement connu de votre téléphone sur une carte GPS.
        </Typography>
        <Typography>
          Il est également possible d'utiliser des applications mobiles pour
          retrouver votre smartphone volé. Notez que les logiciels gratuits ne
          permettent pas d'avoir une localisation précise. Vous devez dans la
          plupart des cas souscrire à un service payant avec d'être satisfait.
        </Typography>
      </Stack>

      <Stack gap={3}>
        <Typography variant="h2">
          Géolocaliser un téléphone volé éteint
        </Typography>
        <Typography>
          Il existe plusieurs méthodes aujourd'hui pour géolocaliser un
          téléphone volé éteint. Peu importe le type du mobile, vous pouvez
          connaître son emplacement géographique et le retrouver plus
          facilement. Découvrez ici deux moyens utilisés pour retrouver
          n'importe quel mobile éteint.
        </Typography>

        <Typography variant="h3">
          Localiser un téléphone volé éteint avec une application de suivi
        </Typography>
        <Typography>
          Il est bien sûr possible de localiser un téléphone volé éteint avec
          une application de suivi. En réalité, plusieurs opérateurs ont mis au
          point des applications pour aider les utilisateurs à retrouver plus
          facilement leurs appareils. Lost Android par exemple, est un outil que
          vous pouvez installer sur votre mobile pour retrouver votre téléphone.
        </Typography>
        <Typography>
          Il est typiquement destiné aux appareils Android. Une fois
          l'application installée, tout ce que vous avez à faire, c'est de vous
          connecter à votre compte Google et de cliquer sur "Contrôle" puis sur
          "Localisation". Vous aurez systématiquement le dernier emplacement
          connu de votre téléphone sur une carte. Il existe également d'autres
          applications telles que Cerberus Antivol, Anti-Theft ou Wheres My
          Droid qui peuvent vous aider à géolocaliser votre téléphone.
        </Typography>

        <Typography variant="h3">
          Localiser un téléphone volé éteint à l'aide du numéro IMEI
        </Typography>
        <Typography>
          Il est aussi possible de localiser votre smartphone volé éteint à
          l'aide du numéro IMEI. En réalité, c'est l'une des façons les plus
          rapides de retrouver votre téléphone. Tout ce que vous avez à faire,
          c'est de communiquer le numéro IMEI du mobile à votre opérateur de
          réseau mobile.
        </Typography>
        <Typography>
          Ce dernier se chargera de géolocaliser le portable soit obtenir sa
          dernière position connue. En utilisant les services de votre
          opérateur, vous pouvez à distance bloquer le téléphone à ce que la
          personne qui l'a volé ne puisse l'utiliser.
        </Typography>
      </Stack>

      <Stack gap={3}>
        <Typography variant="h2">
          Localiser un appareil Android volé lorsqu'il est éteint
        </Typography>
        <Typography>
          Vous pouvez localiser un appareil Android éteint avec la
          fonctionnalité Find My Device. Pour le faire, vous devez au préalable
          activer cette fonction dans les paramètres de votre mobile. Pour
          retrouver l'appareil, il suffit de vous rendre sur la page de Google
          et vous connecter via votre adresse mail. Vous aurez une liste de tous
          les appareils enregistrés avec ce mail.
        </Typography>
        <Typography>
          Sélectionnez le smartphone voulu et vous aurez sa dernière position
          connue. Notez qu'une fois le téléphone trouvé, il est possible que
          vous effaciez à distance toutes vos données, afin que le voleur ne
          puisse utiliser vos informations. Il est aussi possible de bloquer à
          distance l'appareil, et le rendre inutilisable par la personne qui
          vous l'a volé.
        </Typography>
      </Stack>

      <Stack gap={3}>
        <Typography variant="h2">
          Localiser un appareil iOS volé lorsqu'il est éteint
        </Typography>
        <Typography>
          Pour les appareils Apple (iPhone), vous devez activer au préalable, la
          fonctionnalité "localiser mon iPhone" et "Envoyer la dernière position
          connue". Cela se fait dans les paramètres de votre téléphone iOS. Pour
          retrouver l'appareil, il suffit donc de vous rendre sur la page
          d'Apple. Il s'agit de l'adresse web suivante :
          https://www.icloud.com/#find.
        </Typography>
        <Typography>
          Une fois sur cette dernière, vous devez vous connecter en renseignant
          votre adresse iTunes et votre mot de passe. Une fois la section
          ouverte, veuillez cliquer sur localiser et vous pourrez connaître la
          dernière géolocalisation connue de votre téléphone. Dès que vous
          retrouvez l'appareil, libre à vous d'effacer vos données à distance ou
          le bloquer.
        </Typography>
      </Stack>

      <Stack gap={3}>
        <Typography variant="h2">
          Localiser un téléphone avec geolocalisation-mobile.com
        </Typography>
        <Typography>
          Une alternative pour localiser n'importe quel appareil est le site
          geolocalisation-mobile.com. Il s'agit d'une plateforme web très
          plébiscitée par les utilisateurs. Geolocalisation-mobile.com permet de
          connaître l'emplacement géographique de n'importe quel mobile.
        </Typography>
        <Typography>
          Si le téléphone est connecté à un réseau mobile, la localisation est
          plus rapide. Il suffit de 2 minutes pour retrouver n'importe quel
          appareil avec le site geolocalisation-mobile.com.
        </Typography>
      </Stack>

      <Stack gap={3}>
        <Typography variant="h2">
          Comment fonctionne geolocalisation-mobile.com pour localiser un
          téléphone ?
        </Typography>
        <Typography>
          Le principe de fonctionnement de geolocalisation-mobile.com est simple
          à comprendre. Une fois sur la plateforme via Google, vous allez
          renseigner votre numéro de téléphone, ainsi que votre adresse mail
          dans des champs réservés à l'occasion. Ensuite, il vous faut cliquer
          sur le bouton "Démarrer la géolocalisation".
        </Typography>
        <Typography>
          Les services du site ne sont pas gratuits. Vous devez débourser la
          somme de 1 euro afin de récupérer l'emplacement de votre mobile.
        </Typography>
        <Typography>
          Une fois le paiement effectué via les moyens de paiements sécurisés du
          site, la plateforme envoie une notification au destinataire. Ceci est
          effectué dans le but de recevoir son consentement en vue de partager
          sa position géographique. Vous recevrez enfin par mail ou par message
          l'emplacement précis de votre téléphone.
        </Typography>
        <Typography>
          En réalité, geolocalisation-mobile.com est partenaire avec les
          opérateurs de données mobiles du monde. Il s'agit entre autres de
          Orange, Free ou Sosh. Il se sert donc des informations de localisation
          de votre smartphone enregistrées par votre opérateur pour retrouver
          votre mobile.
        </Typography>
      </Stack>
      <Stack sx={{ alignItems: "center" }}>
        <Button variant="contained" href="/" color="error">
          DEMARRER LA GEOLOCALISATION
        </Button>
      </Stack>
    </Stack>
  );
}

export default LocaliserTelephoneVoleEteint;
