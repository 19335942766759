import SearchPhone from "@cospex/client/components/SearchPhone";
import useAuth from "@cospex/client/hooks/useAuth";
import modalAnimationData from "@cospex/client/tracker/img/lottie_tracking_anim.json";
import { Card, Container, Stack } from "@mui/material";
import { Outlet, useNavigate } from "react-router-dom";

export default function AuthOutlet() {
  const { session } = useAuth();
  const navigate = useNavigate();
  const onTrack = () => {
    if (session?.email) {
      navigate("/dashboard");
      return;
    }
    navigate("/payment");
  };

  const searchForm = (
    <Stack sx={{ alignItems: "center", my: "1rem" }}>
      <SearchPhone
        sx={{
          maxWidth: "500px",
          width: "100%",
        }}
        onTrack={onTrack}
        business="tracker"
        modalAnimationData={modalAnimationData}
      />
    </Stack>
  );

  return (
    <Container sx={{ mt: "1rem" }}>
      <Card>
        <Outlet context={{ searchForm }} />
      </Card>
    </Container>
  );
}
