import XiaomiImg from "@/img/localiser-xiaomi.jpg";
import { Box, Button, Stack, Typography } from "@mui/material";
import { useOutletContext } from "react-router-dom";

type ContextType = {
  searchForm: JSX.Element;
};

function LocaliserUnXiaomi() {
  const { searchForm } = useOutletContext<ContextType>();

  return (
    <Stack gap={6}>
      <Stack gap={3}>
        <Typography variant="h2">Localiser son Xiaomi</Typography>
        <Typography>
          Xiaomi est une marque de téléphone dont la notoriété ne cesse de
          croître sur le marché en raison de ses performances. Entre autres,
          sachez que le fabricant a mis en place des dispositions pour que vous
          puissiez retrouver facilement votre smartphone en cas de perte ou de
          vol. Outre cette astuce, vous avez également la possibilité d'utiliser
          le service geolocalisation-mobile.com qui garantit une satisfaction
          optimale, quel que soit le modèle que vous utilisez. Si vous vous
          demandez comment localiser votre Xiaomi, voici des éléments de réponse
          et quelques astuces pour mieux vous orienter !
        </Typography>
        {searchForm}
        <Box component="img" src={XiaomiImg} />
      </Stack>

      <Stack gap={3}>
        <Typography variant="h2">
          Comment localiser un portable Xiaomi en cas de perte ou vol ?
        </Typography>
        <Typography>
          Plusieurs possibilités se présentent à vous pour{" "}
          <b>localiser votre Xiaomi volé</b>. Il vous reviendra juste d'opter
          pour la plus adéquate.
        </Typography>
      </Stack>

      <Stack gap={3}>
        <Typography variant="h3">
          Localiser votre Xiaomi avec geolocalisation-mobile.com
        </Typography>
        <Typography>
          Le service gelocalisation-mobile.com apparaît en tête de liste et fait
          office de la meilleure solution pour{" "}
          <b>localiser votre Xiaomi perdu</b>. Il faudra vous diriger sur la
          plateforme pour vous rendre compte qu'elle est dotée d'une interface
          fluide qui simplifie son utilisation. Il convient de souligner que
          notre service est efficace, peu importe le système d'exploitation
          installé sur le mobile et est compatible avec :
        </Typography>
        <Typography component="div">
          <ul>
            <li>toute marque</li>
            <li>tout opérateur</li>
            <li>tout modèle</li>
          </ul>
        </Typography>
        <Typography>
          De plus, l'autre avantage est que nous vous garantissons une précision
          fiable, car nous vous permettons de connaître la position exacte du
          téléphone. Par ailleurs, nous disposons d'un service client prompt qui
          reste à votre écoute et ce, tous les jours de la semaine et à chaque
          heure.
        </Typography>
      </Stack>

      <Stack gap={3}>
        <Typography variant="h3">
          Localiser votre Xiaomi avec Xiaomi cloud
        </Typography>
        <Typography>
          Le fabricant de la marque a conçu Xiaomi cloud dans le but de vous
          aider à retrouver votre smartphone en cas de perte ou de vol. Pour
          cela, vous devez vous connecter sur i.mi.com afin d'accéder à votre
          espace (Mi-cloud) et lancer la procédure de localisation. Vous
          remarquerez que l'emplacement de votre téléphone sera indiqué par un
          point vert sur une carte, même si le résultat n'est pas fiable. En
          effet, pour que la position vous soit donnée, il faudra en amont
          activer ''Localiser mon appareil'' dans les paramètres. Par
          conséquent, si vous ne l'aviez pas fait avant le vol du smartphone
          Xiaomi, vous ne pourrez pas le retrouver, ce qui constitue un
          inconvénient.
        </Typography>
      </Stack>

      <Stack gap={3}>
        <Typography variant="h3">
          Localiser votre Xiaomi avec Google Find my service
        </Typography>
        <Typography>
          Le géant américain vous propose également son aide pour vous permettre
          de géolocaliser votre Xiaomi en cas de perte ou de vol. Il s'agit de
          Google Find my service, une option que vous devez d'abord rendre
          opérationnelle en passant par Play Store. Ensuite, connectez-vous sur
          le site android.com.find et sélectionnez l'appareil que vous désirez
          retrouver. Cependant, sachez que la localisation réelle ne sera pas
          accessible. Aussi, l'astuce pose le problème d'exposition de la vie
          privée, car pour en bénéficier, vous devez donner votre consentement
          pour être suivi dans vos déplacements.
        </Typography>
      </Stack>

      <Stack gap={3}>
        <Typography variant="h3">
          Comment fonctionne geolocalisation-mobile.com pour localiser votre
          Xiaomi ?
        </Typography>
        <Typography>
          D'abord, il faut préciser que le processus pour retrouver votre Xiaomi
          sur notre site est facile et rapide. Une fois que vous êtes sur la
          page d'accueil, renseignez le numéro de téléphone que vous comptez
          localiser et réalisez le paiement en toute sécurité. Dès que la
          transaction est effectuée, le destinataire est alerté par un message
          afin de donner son accord pour partager sa position. À la fin, vous
          allez recevoir par e-mail ou par SMS l'emplacement exact de votre
          Xiaomi, peu importe où il se trouve. De plus, sachez que vous avez la
          possibilité d'obtenir une géolocalisation personnalisée en remplissant
          vos informations personnelles (nom et prénom).
        </Typography>
      </Stack>

      <Stack gap={3}>
        <Typography variant="h2">
          Questions à propos de la localisation d'un Xiaomi
        </Typography>
        <Typography>
          Voici un petit guide qui regroupe les préoccupations fréquentes
          soulevées par de nombreux utilisateurs de la marque Xiaomi.
        </Typography>

        <Typography variant="h3">
          Peut-on géolocaliser un Xiaomi avec IMEI ?
        </Typography>
        <Typography>
          Comme la plupart des smartphones exploités sous le système Android,
          vous avez la possibilité de localiser votre Xiaomi avec IMEI. Il
          s'agit d'un long numéro qui permet d'identifier chaque téléphone que
          vous pourrez obtenir en tapant *#06# ou en vérifiant sous le dos de la
          batterie. Pour géolocaliser votre Xiaomi avec IMEI, contactez votre
          opérateur et communiquez-lui les chiffres et il pourra vous donner son
          emplacement approximatif.
        </Typography>

        <Typography variant="h3">
          Peut-on géolocaliser un Xiaomi gratuitement ?
        </Typography>
        <Typography>
          À la lumière des astuces détaillées plus haut, vous pouvez
          effectivement localiser votre Xiaomi gratuitement en optant pour la
          solution de votre choix. À ce niveau, il faut noter que les
          applications gratuites ne sont pas efficaces, contrairement au service
          payant de geolocalisation-mobile.com.
        </Typography>

        <Typography variant="h3">
          Mon Xiaomi est éteint ou n'a plus de batterie, puis-je tout de même le
          localiser ?
        </Typography>
        <Typography>
          Non, vous ne pouvez pas localiser un Xiaomi éteint ou localiser un
          Xiaomi sans batterie, parce que le téléphone doit être allumé pour
          l'opération. Si ce n'est pas le cas, vous ne connaîtrez que le dernier
          emplacement où le smartphone a été géolocalisé.
        </Typography>

        <Typography variant="h3">
          L'option de géolocalisation n'est pas activée sur mon Xiaomi, la
          localisation est-elle possible ?
        </Typography>
        <Typography>
          Vous devez savoir que la localisation n'est pas possible si l'option
          de géolocalisation n'a pas été activée avant la perte de votre Xiaomi.
        </Typography>
      </Stack>

      <Stack gap={3}>
        <Typography variant="h2">
          Que faire en cas de vol de votre Xiaomi ?
        </Typography>
        <Typography>
          En cas de vol de votre Xiaomi, vous pouvez essayer de le localiser en
          recourant aux options de Google ou aux fonctionnalités du Xiaomi
          Cloud. Cependant, pour être sûr d'obtenir une réponse précise, le
          service geolocalisation-mobile.com est l'astuce la plus recommandée,
          car vous aurez les coordonnées exactes du mobile. De plus, vous pouvez
          contacter votre opérateur afin qu'il suspende votre ligne et rende
          impossible l'utilisation de ce dernier. Notez qu'il est également
          recommandé d'aller déposer une plainte auprès des forces de l'ordre
          dans le but de signaler le vol.
        </Typography>
        <Typography>
          Une fois au poste de police, fournissez le code IMEI de votre Xiaomi
          et donnez un maximum de détails en précisant les caractéristiques.
          Pour finir, connectez-vous sur l'interface du fabricant pour procéder
          à l'effacement à distance pour que vos données ne tombent pas en de
          mauvaises mains.
        </Typography>
      </Stack>

      <Stack gap={3}>
        <Typography variant="h2">
          Les modèles de téléphones Xiaomi à localiser
        </Typography>
        <Typography>
          Voici une liste des modèles de smartphones Xiaomi que vous pouvez
          facilement localiser avec les solutions susmentionnées.
        </Typography>

        <Typography variant="h3">Redmi Note 11</Typography>
        <Typography>
          S'il y a bien un modèle populaire dans les gammes proposées par le
          fabricant, c'est le Redmi Note 11. L'appareil est équipé de capteur
          d'empreintes, de deux caméras (avant et arrière) ainsi que d'un GPS L1
          qui permet de le localiser.
        </Typography>

        <Typography variant="h3">Xiaomi Pad 5</Typography>
        <Typography>
          Un autre modèle apprécié par les utilisateurs est la Xiaomi Pad 5 dont
          la puissance de la batterie est de 8720 mAh. Ce mobile est conçu avec
          un écran 11 pouces et la fonctionnalité ''Localiser mon appareil'' est
          préinstallée pour faciliter sa géolocalisation.
        </Typography>

        <Typography variant="h3">Redmi Note 10 Pro</Typography>
        <Typography>
          Si vous disposez du modèle Redmi Note 10 Pro de la marque Xiaomi, vous
          pouvez aussi le localiser avec facilité. Elle est dotée d'une NFC
          multifonction ainsi que d'un système de sécurité via reconnaissance
          faciale. Ce modèle est intégré de capteur de proximité, d'émetteur
          infrarouge et aussi de Galileo E1 grâce auquel vous pourrez le
          localiser.
        </Typography>
      </Stack>
      <Stack sx={{ alignItems: "center" }}>
        <Button variant="contained" href="/" color="error">
          DEMARRER LA GEOLOCALISATION
        </Button>
      </Stack>
    </Stack>
  );
}

export default LocaliserUnXiaomi;
