import IPhoneImg from "@/img/localiser-un-iphone.jpg";
import { Box, Button, Stack, Typography } from "@mui/material";
import { useOutletContext } from "react-router-dom";

type ContextType = {
  searchForm: JSX.Element;
};

function LocaliserSonIphone() {
  const { searchForm } = useOutletContext<ContextType>();

  return (
    <Stack gap={6}>
      <Stack gap={3}>
        <Typography variant="h2">Localiser son iPhone</Typography>
        <Typography>
          Les cas de vol ou de perte de smartphone sont fréquents et ce, quelles
          que soient les dispositions sécuritaires que vous prenez.
        </Typography>
        <Typography>
          Les détenteurs d'un téléphone de marque Apple ont par exemple la
          possibilité d'utiliser la fonctionnalité servant à localiser un iPhone
          si l'appareil est volé ou perdu. D'autres options peuvent vous aider
          dans ce sens, c'est notamment le cas du service
          geolocalisation-mobile.com qui fait office de solution la plus
          efficace. Si vous souhaitez en savoir davantage, nous vous présentons
          les conseils et informations pour localiser rapidement votre iPhone.
        </Typography>
        {searchForm}
        <Box component="img" src={IPhoneImg} />
      </Stack>

      <Stack gap={3}>
        <Typography variant="h2">
          Comment localiser un portable Iphone en cas de perte ou vol ?
        </Typography>
        <Typography>
          Dans le but de localiser votre Iphone volé ou perdu, nous vous
          suggérons les astuces suivantes pour y parvenir.
        </Typography>

        <Typography variant="h3">
          Localiser votre téléphone Apple avec géolocalisation-mobile.com
        </Typography>
        <Typography>
          Équipé d'une interface fluide et simple à utiliser, le service
          geolocalisation-mobile.com est la première solution qui puisse vous
          permettre de localiser votre téléphone Apple. Sa particularité est
          relative au fait qu'il garantit aux utilisateurs une compatibilité
          avec tous les opérateurs, toutes les marques ainsi que tous les
          modèles de smartphone. Donc, peu importe le système d'exploitation
          installé sur l'appareil (IOS ou Android), nous vous permettons
          d'obtenir une géolocalisation ultra précise. Avec notre service,
          l'emplacement exact de votre Iphone sera affiché sur une carte qui est
          valable dans toutes les régions du monde.
        </Typography>

        <Typography variant="h3">
          Localiser votre Iphone avec l'application "Localiser"
        </Typography>
        <Typography>
          Le fabricant Apple a mis en place l'application "Localiser" pour
          permettre à ses utilisateurs de retrouver leur téléphone s'il est
          perdu ou volé. Toutefois, vous devez activer en amont cette
          application dans les réglages sans quoi, cette astuce risque de ne pas
          être efficace. Ce qui représente un inconvénient contrairement au
          service geolocalisation-mobile.com qui ne requiert pas une telle
          opération au préalable. En outre, en utilisant la fonctionnalité
          Localiser, vous devez donner votre consentement pour la collecte de
          vos données personnelles. Il se pose donc un problème de
          confidentialité, car l'application devra suivre chacun de vos
          mouvements, synonyme d'exposition de la vie privée.
        </Typography>

        <Typography variant="h3">
          Localiser votre Iphone avec icloud d'Apple
        </Typography>
        <Typography>
          Pour les utilisateurs d'un système iOS, il faudra vous connecter sur
          l'espace iCloud.com/find avec votre identifiant icloud et cliquer sur
          l'appareil que vous souhaitez géolocaliser. Une fois ceci fait, la
          plateforme vous donnera la position du téléphone sur un plan affiché
          sur l'interface. Néanmoins, sachez que cette fonction présente aussi
          des limites, car seul le dernier emplacement connu du smartphone sera
          indiqué.
        </Typography>
      </Stack>

      <Stack gap={3}>
        <Typography variant="h2">
          Comment fonctionne geolocalisation-mobile.com pour localiser votre
          Iphone ?
        </Typography>
        <Typography>
          La demande de localisation via notre service est rapide. D'abord,
          pensez à renseigner le numéro du téléphone à retrouver puis dans un
          second temps passer au paiement. Nous tenons à vous signaler que ce
          dernier est 100 % sécurisé, car vos informations bancaires sont
          protégées grâce à notre système de cryptage. Dès que vous réalisez le
          paiement, un message est envoyé au destinataire afin qu'il donne son
          autorisation pour partager sa position. Par la suite, cette dernière
          vous sera indiquée soit par mail ou par SMS, ce qui vous permettra de
          repérer l'emplacement exact de votre Iphone.
        </Typography>
      </Stack>

      <Stack gap={3}>
        <Typography variant="h2">
          Questions à propos de la localisation d'un Iphone
        </Typography>
        <Typography>
          De nombreuses interrogations reviennent dans l'esprit des utilisateurs
          Apple et pour les orienter, voici quelques éléments de réponse.
        </Typography>

        <Typography variant="h3">
          Peut-on géolocaliser un Iphone avec IMEI ?
        </Typography>
        <Typography>
          La réponse à cette question est affirmative. Oui, vous pouvez
          localiser votre Iphone avec IMEI, lequel représente l'identité
          numérique du smartphone. Pour rappel, il s'agit d'une suite de 15
          chiffres que vous trouverez dans les réglages du téléphone, en allant
          dans la rubrique ''Général'' puis en sélectionnant ''Informations''.
          Si vous comptez géolocaliser votre téléphone Apple avec IMEI,
          contactez votre opérateur qui pourra, soit bloquer le téléphone à
          distance soit vous dire sa position approximative.
        </Typography>

        <Typography variant="h3">
          Peut-on géolocaliser un Iphone gratuitement ?
        </Typography>
        <Typography>
          Oui, vous avez la possibilité de localiser votre Iphone gratuitement
          en recourant aux solutions du fabricant conçues dans ce sens.
          Néanmoins, vous devez savoir que ces fonctionnalités sont moins
          efficaces comparativement au service geolocalisation-mobile.com, même
          si celui-ci est payant.
        </Typography>

        <Typography variant="h3">
          Mon Iphone est éteint ou n'a plus de batterie, puis-je tout de même le
          localiser ?
        </Typography>
        <Typography>
          Il est tout à fait possible de localiser un Iphone éteint ou de
          localiser un Iphone sans batterie grâce à l'application Localiser.
          Toutefois, gardez à l'esprit que cette fonction est limitée, car si le
          smartphone est hors ligne, seule la dernière position connue vous sera
          donnée. Il convient de préciser que ce défaut n'est pas constaté si
          votre Iphone dispose au moins de la mise à jour iOS 15.
        </Typography>

        <Typography variant="h3">
          L'option de géolocalisation de mon Iphone n'est pas activée, la
          localisation est-elle possible ?
        </Typography>
        <Typography>
          Non, la localisation de votre Iphone n'est pas possible si l'option de
          géolocalisation est en mode désactivé.
        </Typography>
      </Stack>

      <Stack gap={3}>
        <Typography variant="h2">
          Que faire en cas de vol de votre Iphone ?
        </Typography>
        <Typography>
          La première possibilité qui se présente à vous est d'essayer de
          localiser votre Iphone grâce aux diverses méthodes ci-dessus. Ensuite,
          vous pouvez marquer le smartphone comme «Perdu» afin de le verrouiller
          à distance et sécuriser les informations sensibles qui s'y retrouvent.
          En cas de vol, il est aussi recommandé d'aller voir les autorités
          compétentes de préférence la police pour signaler cela. Elle vous
          demandera de fournir le numéro de série du téléphone que vous
          obtiendrez en allant dans les paramètres ou en composant *#06#.
        </Typography>
        <Typography>
          Il faut également souligner qu'en cas de vol, les utilisateurs
          d'Iphone peuvent déposer une réclamation si le smartphone est couvert
          par l'AppleCare+. De plus, le fabricant leur donne la main pour
          supprimer le contenu du mobile à distance, mais cela ne leur permettra
          plus de suivre l'emplacement. Vous pouvez aussi contacter votre
          opérateur en cas de vol qui, de son côté, bloquera l'envoi des SMS,
          les appels et l'utilisation des données.
        </Typography>
        <Typography>
          Pour finir, les utilisateurs d'un téléphone Iphone peuvent effacer le
          mobile de la liste des smartphones reliés à leur compte icloud.
        </Typography>
      </Stack>

      <Stack gap={3}>
        <Typography variant="h2">
          Les modèles de téléphones iPhone à localiser
        </Typography>
        <Typography>
          Il serait impossible de parler des modèles populaires sans évoquer
          l'Iphone 11 Pro Max équipé d'une caméra TrueDepth. Avec une autonomie
          estimée à 5 heures, l'appareil est localisable grâce au Wi-Fi, à la
          boussole numérique ou au GPS.
        </Typography>
        <Typography>
          L'Iphone 12 Pro Max fait office du modèle le plus populaire apprécié
          par de nombreux utilisateurs. Appareil haut de gamme, il est conçu
          avec un capteur photo 12 Mpx, une option de sécurité avec Face ID et
          est intégré de l'app Localiser.
        </Typography>
        <Typography>
          Il y a également le nouveau modèle, l'Iphone 13 doté d'un écran HDR
          6,1 pouces, résistant à l'eau et à la poussière. Il faut souligner que
          sa géolocalisation est possible grâce au GPS dont il est équipé.
        </Typography>
        <Typography>
          Il faut noter que cette liste de modèles n'est pas exhaustive et ne
          sont pas les seuls que vous pouvez localiser.
        </Typography>
      </Stack>
      <Stack sx={{ alignItems: "center" }}>
        <Button variant="contained" href="/" color="error">
          DEMARRER LA GEOLOCALISATION
        </Button>
      </Stack>
    </Stack>
  );
}

export default LocaliserSonIphone;
