import FreeImg from "@/img/localiser-un-telephone-free.jpg";
import { Box, Button, Stack, Typography } from "@mui/material";
import { useOutletContext } from "react-router-dom";

type ContextType = {
  searchForm: JSX.Element;
};

function LocaliserTelephoneFreeVolePerdu() {
  const { searchForm } = useOutletContext<ContextType>();

  return (
    <Stack gap={6}>
      <Stack gap={3}>
        <Typography variant="h2">
          Localiser téléphone Free volé ou perdu
        </Typography>
        <Typography>
          Si vous venez de perdre votre téléphone Free ou alors s'il vous a été
          dérobé, vous avez la possibilité de le localiser. Nombreuses sont ces
          personnes qui ne savent généralement pas quoi faire lorsqu'elles ont
          égaré leur smartphone. Voici à cet effet quelques méthodes simples qui
          vous seront d'une grande utilité.
        </Typography>
        {searchForm}
        <Box component="img" src={FreeImg} />
      </Stack>

      <Stack gap={3}>
        <Typography variant="h2">
          Comment localiser un téléphone free en cas de vol ?
        </Typography>
        <Typography>
          Avec l'évolution de la technologie, géolocaliser un téléphone Free
          volé n'est plus du tout un problème. Plus besoin de stresser lorsque
          vous avez égaré votre mobile ou alors lorsque vous venez de vous faire
          agresser. Ces quelques méthodes peuvent vous sortir de l'une de ces
          mauvaises situations.
        </Typography>
      </Stack>

      <Stack gap={3}>
        <Typography variant="h3">
          Localiser son téléphone Free avec geolocalisation-mobile.com
        </Typography>
        <Typography>
          Lorsque vous êtes à la recherche de votre appareil mobile, utilisez le
          service geolocalisation-mobile.com. Il s'agit de l'une des solutions
          qui s'est avérée utile pour de nombreuses personnes à travers le
          monde. Elle vous aidera en cas de perte ou de vol. Cette plateforme en
          ligne est simple et facile à utiliser. Vous n'avez pas du tout besoin
          de connaissances techniques pour vous en servir.
        </Typography>
        <Typography>
          S'il s'agit d'une meilleure assurance de nos jours, c'est parce
          qu'elle gère tous types de système d'exploitation (IOS et Android). De
          plus, quel que soit le modèle de votre smartphone, la géolocalisation
          est possible. C'est la raison pour laquelle de nombreuses marques
          collaborent avec cette plateforme en ligne. Notez que ce site internet
          est capable de vous donner la position exacte de votre téléphone
          dérobé.
        </Typography>
      </Stack>

      <Stack gap={3}>
        <Typography variant="h3">
          Géolocaliser d'un téléphone Android Free
        </Typography>
        <Typography>
          En étant client chez l'opérateur Free, pour retrouver votre téléphone
          Android en cas de vol, vous avez aussi la possibilité de passer par
          Google Find My Service. Pour le localiser, allez tout simplement sur
          votre application Play Store. Une fois vous y êtes rendu, vous devez
          vous connecter sur le site android.com.find. Assurez-vous d'avoir
          activé votre connexion internet. Bien évidemment, le smartphone que
          vous utilisez pour la recherche doit avoir une carte SIM valide.
        </Typography>
        <Typography>
          Il est toutefois important de signaler que la localisation de
          l'appareil mobile de n'importe qui expose vos données personnelles.
          Par ailleurs, cette solution ne vous livre qu'une position
          approximative de votre portable. Ainsi, le service
          géolocalisation-mobile.com est plus sûr et fiable.
        </Typography>
      </Stack>

      <Stack gap={3}>
        <Typography variant="h3">Géolocaliser son iPhone Free</Typography>
        <Typography>
          Il est aussi possible de localiser un iPhone si vous êtes client chez
          Free à partir d'une application propre à ce type d'appareil. Pour ce
          faire, il vous suffit d'avoir un autre Apple. À partir de ce dernier,
          vous pouvez savoir où se trouve votre portable. La procédure quant à
          elle est très simple. Commencez par aller sur la page iCloud.com/find.
        </Typography>
        <Typography>
          Veillez surtout à ce que la fonction de géolocalisation soit active
          sur le téléphone dérobé. Si tel n'est pas le cas, il ne va pas
          apparaître dans la liste. Cependant, notez que vous avez la
          possibilité de protéger votre compte même dans le cas où la
          localisation n'est pas activée sur le smartphone Apple.
        </Typography>
      </Stack>

      <Stack gap={3}>
        <Typography variant="h2">
          Comment fonctionne geolocalisation-mobile.com pour localiser son
          téléphone Free ?
        </Typography>
        <Typography>
          Pour repérer votre Smartphone Free grâce à cette plateforme en ligne,
          rien de plus simple. Vous allez premièrement devoir vous rendre sur sa
          page d'accueil. Lorsque c'est fait, vous devez renseigner le numéro de
          l'appareil que vous recherchez. Effectuez ensuite votre paiement pour
          l'offre d'essai. Il est utile de rappeler que toutes les transactions
          disposent d'une sécurité optimale.
        </Typography>
        <Typography>
          Lorsque le règlement est fait, celui qui détient l'appareil reçoit une
          alerte lui demandant de partager sa position. Vous obtenez alors un
          e-mail ou un SMS qui vous dit où se trouve exactement le portable. Il
          est aussi possible d'opter pour une recherche personnalisée en donnant
          plus d'informations personnelles.
        </Typography>
      </Stack>

      <Stack gap={3}>
        <Typography variant="h2">
          Questions à propos de la localisation d'un téléphone Free volé
        </Typography>
        <Typography>
          Il existe certainement des questions qui vous intéressent à propos de
          la localisation d'un téléphone Free. Ces réponses pourraient donc vous
          aider.
        </Typography>

        <Typography variant="h3">
          Peut-on géolocaliser un téléphone Free avec IMEI ?
        </Typography>
        <Typography>
          Il est bel et bien possible de le faire. L'IMEI est un long numéro
          correspondant à l'identifiant de votre appareil mobile. Pour l'avoir,
          il vous suffit de taper le code *#06#. Vous pouvez aussi le retrouver
          sur l'arrière de votre batterie. Si vous souhaitez géolocaliser votre
          portable de cette manière, il vous suffit de contacter votre opérateur
          de téléphonie. Ce dernier peut aussi vous aider à bloquer votre
          smartphone pour que personne ne puisse s'en servir.
        </Typography>

        <Typography variant="h3">
          Peut-on géolocaliser un téléphone Free gratuitement ?
        </Typography>
        <Typography>
          Que vous soyez chez Free ou non, vous avez la possibilité de localiser
          un téléphone gratuitement en passant par certaines applications. Notez
          cependant que les résultats ne sont pas fiables. Il vaut mieux
          débourser une petite somme chez geolocalisation-mobile.com. Le service
          gratuit n'est pas toujours fiable.
        </Typography>

        <Typography variant="h3">
          Mon téléphone Free est éteint ou n'a plus de batterie, puis-je tout de
          même le localiser ?
        </Typography>
        <Typography>
          Il n'est pas du tout possible de localiser un téléphone éteint ou sans
          batterie. Seul un appareil mobile allumé peut être géolocalisé. Dans
          le cas où vous essayez tout de même de le faire, vous n'allez avoir
          que la dernière position lorsque l'appareil était en marche.
        </Typography>

        <Typography variant="h3">
          L'option de géolocalisation n'est pas activée sur mon téléphone Free,
          la localisation est-elle possible ?
        </Typography>
        <Typography>
          Vous devez toujours laisser la géolocalisation de votre téléphone
          activée. Dans le cas contraire, il va être impossible de le retrouver
          en cas de vol. Si la personne l'ayant dérobé désactive cette option,
          vous n'aurez donc pas la possibilité de le localiser.
        </Typography>
      </Stack>

      <Stack gap={3}>
        <Typography variant="h2">
          Que faire en cas de vol de votre téléphone Free ?
        </Typography>
        <Typography>
          Avant de penser à la géolocalisation de votre téléphone Free, il y a
          certains gestes incontournables que vous devez faire en cas de vol de
          votre téléphone. La première des choses en cas de vol est d'aller
          déposer une plainte à la gendarmerie ou à la police. Le but est
          surtout de faire une déclaration de perte. Pour ce faire, vous devez
          être certain d'avoir votre code IMEI.
        </Typography>
        <Typography>
          Les forces de l'ordre vont également vous demander d'autres
          informations personnelles afin de mieux s'occuper du dossier. Vous
          pouvez aussi vous connecter sur le site officiel du fabricant du
          téléphone Free afin d'effacer vos données personnelles à distance.
          Ainsi vous êtes certain d'avoir protégé votre intimité.
        </Typography>
        <Typography>
          Vous savez désormais comment localiser votre Free téléphone volé.
          Comme vous l'avez constaté, il existe de nombreuses solutions
          gratuites pour retrouver votre appareil. Cependant, ces dernières ne
          vous garantissent pas des résultats fiables. Il est mieux de vous
          tourner vers le service geolocalisation-mobile.com. Il est payant,
          mais vous permettra de rapidement trouver votre portable.
        </Typography>
      </Stack>
      <Stack sx={{ alignItems: "center" }}>
        <Button variant="contained" href="/" color="error">
          DEMARRER LA GEOLOCALISATION
        </Button>
      </Stack>
    </Stack>
  );
}

export default LocaliserTelephoneFreeVolePerdu;
