import SamsungImg from "@/img/localiser-un-samsung.jpg";
import { Box, Button, Stack, Typography } from "@mui/material";
import { useOutletContext } from "react-router-dom";

type ContextType = {
  searchForm: JSX.Element;
};

function LocaliserUnSamsung() {
  const { searchForm } = useOutletContext<ContextType>();

  return (
    <Stack gap={6}>
      <Stack gap={3}>
        <Typography variant="h2">Localiser un Samsung</Typography>
        <Typography>
          Se passer de son téléphone Samsung peut être source de beaucoup
          d'angoisses. Vos activités, vos rendez-vous et vos projets y sont tous
          programmés. Toutefois, il peut arriver que suite à une situation
          malencontreuse, vous soyez dépossédé de votre appareil Samsung. Il
          peut s'agir du vol ou de la perte de votre téléphone. Si vous vous
          trouvez dans l'une de ces situations, voici alors tout ce qu'il faut
          savoir pour géolocaliser votre smartphone Samsung et le retrouver !
        </Typography>
        {searchForm}
        <Box component="img" src={SamsungImg} />
      </Stack>

      <Stack gap={3}>
        <Typography variant="h2">
          Comment localiser un portable Samsung en cas de perte ou de vol ?
        </Typography>
        <Typography>
          Lorsque votre smartphone est perdu ou volé, vous pouvez facilement le
          pister de différentes façons. Cela dit, il est possible de localiser
          votre téléphone Samsung grâce à géolocalsation-mobile.com. Ensuite,
          vous pouvez le faire avec l'application SmartThings de Samsung. Enfin,
          grâce à Google Find My Service, vous pouvez aussi mettre la main sur
          votre téléphone.
        </Typography>
      </Stack>

      <Stack gap={3}>
        <Typography variant="h3">
          Localiser son téléphone avec géolocalisation-mobile.com
        </Typography>
        <Typography>
          Avec géolocalisation-mobile.com, vous pouvez soit localiser votre
          Samsung volé, le verrouiller ou effacer vos données à distance. Pour
          ce faire, allez sur android.com/fin pour vous connecter à votre compte
          Google. Une notification sera ensuite envoyée sur le téléphone perdu.
          Vous verrez alors sur la carte, la position approximative ou la
          dernière position de l'appareil. Une fois que l'emplacement de
          l'appareil s'affiche, vous pouvez faire sonner le téléphone (volume
          maximal durant 5 minutes). Vous pouvez aussi sécuriser l'appareil en
          le verrouillant avec un code, un mot de passe ou un schéma. Il est
          également possible d'effacer les données de la carte interne du
          téléphone.
        </Typography>
      </Stack>

      <Stack gap={3}>
        <Typography variant="h3">
          Localiser son téléphone avec l'application Smart Things Find
        </Typography>
        <Typography>
          Smart Things Find est une application conçue spécialement par Samsung
          pour vous aider à retrouver votre téléphone perdu. La localisation se
          faire grâce au Bluetooth et la bande ultra-large. Voici la procédure à
          suivre :
        </Typography>
        <Typography component="div">
          <ul>
            <li>
              ouvrir Smart Things Find et sélectionner l'appareil à localiser
            </li>
            <li>l'écran affichera des indications pour vous guider</li>
            <li>
              lorsque vous atteignez le niveau du portable sur l'écran, vous
              pouvez le faire sonner avec la fonction Search Nearby.
            </li>
          </ul>
        </Typography>
      </Stack>

      <Stack gap={3}>
        <Typography variant="h3">
          Localiser son téléphone avec Google Find my Service
        </Typography>
        <Typography>
          Pour localiser votre Samsung perdu avec le service de localisation
          Google Find My service, vous devez suivre la procédure qui suit. Allez
          sur android.com/find pour vous connecter à votre compte Google. Après
          connexion, une notification ira vers le téléphone perdu. Une carte
          s'affiche avec les informations relatives à l'emplacement de
          l'appareil. A vous maintenant de décider soit de verrouiller le
          téléphone, soit d'effacer les données de la carte SD interne.
        </Typography>
      </Stack>

      <Stack gap={3}>
        <Typography variant="h3">
          Comment fonctionne géolocalisation-mobile.com pour localiser son
          Samsung ?
        </Typography>
        <Typography>
          Pour localiser votre Samsung éteint avec géolocalisation-mobile.com,
          allez sur le site Find My Mobile pour vous connecter à votre compte
          Samsung. Find My mobile peut être consulté depuis n'importe quel
          téléphone. Si vous disposez de plusieurs smartphones, ils
          s'afficheront tous. Sélectionnez alors le téléphone à retrouver. Par
          la suite, appuyez sur localiser pour avoir l'emplacement de votre
          téléphone Samsung sur la carte.
        </Typography>
      </Stack>

      <Stack gap={3}>
        <Typography variant="h2">
          Questions à propos de la localisation d'un Samsung
        </Typography>
        <Typography>
          Voici quelques questions qui reviennent le plus souvent lorsqu'il
          s'agit de localiser un Samsung.
        </Typography>

        <Typography variant="h3">
          Peut-on géolocaliser un Samsung avec IMEI ?
        </Typography>
        <Typography>
          L'IMEI (International Mobile Identity) est souvent utilisé pour
          retrouver des appareils égarés. Cependant, vous ne pourrez pas
          géolocaliser votre appareil Samsung avec son IMEI. Néanmoins, si vous
          connaissez le numéro de téléphone relié au modèle de votre appareil
          Samsung, vous pouvez utiliser le service de géolocalisation. L'outil
          localiseruntelephone.fr est conçu pour vous aider à retrouver votre
          mobile dans ce cas.
        </Typography>

        <Typography variant="h3">
          Peut-on géolocaliser un Samsung gratuitement
        </Typography>
        <Typography>
          Il est bien possible de localiser votre Samsung gratuitement. Avec une
          bonne maîtrise des étapes et une connexion fiable, vous pouvez bel et
          bien y arriver. La première étape à suivre à cet effet consiste à
          aller sur le site android.com/find pour vous connecter à votre compte
          Google. Une notification ira sur votre téléphone. À cet instant, la
          carte vous affiche la dernière position enregistrée pour l'appareil.
        </Typography>

        <Typography variant="h3">
          Mon Samsung est éteint ou n'a plus de batterie, puis-je tout de même
          le localiser ?
        </Typography>
        <Typography>
          Il est aussi possible de localiser votre Samsung sans batterie. La
          procédure est la suivante :
        </Typography>
        <Typography component="div">
          <ul>
            <li>
              utiliser un ordinateur ou un autre mobile pour vous rendre sur le
              site Find My Device
            </li>
            <li>
              utiliser votre adresse Gmail pour vous connecter à votre compte
              Google
            </li>
            <li>
              la dernière localisation GPS de votre appareil Samsung s'affichera
              sur une carte
            </li>
            <li>
              vous pouvez alors : soit localiser simplement le portable, le
              bloquer ou effacer ses données.
            </li>
          </ul>
        </Typography>
        <Typography>
          Si vous suivez toutes ces étapes, vous aurez sans aucun doute une idée
          sur le nouvel emplacement de votre téléphone.
        </Typography>

        <Typography variant="h3">
          L'option de géolocalisation n'est pas activée sur mon Samsung, la
          localisation est-elle possible ?
        </Typography>
        <Typography>
          Si la géolocalisation est désactivée, vous pouvez compter sur le
          système des antennes relais. Ce dernier fonctionne sur la base des
          antennes relais installées sur la localité. Il n'est pas assez précis
          compte tenu de l'insuffisance d'antennes relais sur toute l'étendue du
          territoire. Néanmoins, il fera une triangulation basée sur votre
          dernier appel effectué, votre dernier SMS ou votre dernière connexion
          sur internet. En ville, la localisation sera plus rapide en raison de
          la couverture de plusieurs antennes relais.
        </Typography>
      </Stack>

      <Stack gap={3}>
        <Typography variant="h2">
          Que faire en cas de vol de votre Samsung ?
        </Typography>
        <Typography>
          Lorsque vous perdez votre téléphone Samsung, il est important de
          suivre ces étapes pour le retrouver.
        </Typography>

        <Typography variant="h3">
          Commencer par Stopper la ligne de votre mobile
        </Typography>
        <Typography>
          Une fois que vous constatez le vol, il faut le faire savoir au service
          client qui se chargera de suspendre la ligne. Ceci empêche
          l'utilisation frauduleuse du téléphone en votre nom. L'opérateur vous
          envoie alors une nouvelle carte SIM. Vous lui envoyez aussi une
          plainte pour vol de votre mobile Samsung.
        </Typography>

        <Typography variant="h3">
          Signaler le vol auprès des autorités
        </Typography>
        <Typography>
          À la gendarmerie, vous communiquez le numéro IMEI de votre appareil
          Samsung et vous portez plainte. L'IMEI est un numéro unique à tous les
          appareils. Il se trouve sous votre batterie ou sur la boîte d'achat de
          l'appareil. Vous fournirez les détails sur les circonstances du vol,
          la marque et le modèle du mobile dérobé Si vous avez une assurance
          mobile contacter l'assureur
        </Typography>

        <Typography>
          Vous allez compléter une déclaration de vol auprès de votre assureur,
          que ce soit auprès de Samsung Care+ ou Constructeur Samsung. Ceci vous
          permettra d'obtenir réparations comme prévu dans le cadre de votre
          contrat.
        </Typography>

        <Typography variant="h3">Chercher à changer le mot de passe</Typography>
        <Typography>
          Connectez-vous depuis un mobile avec votre compte Samsung. Au
          préalable, il faut que les paramètres de contrôle à distance soient
          activés. Allez dans paramètres puis dans données biométriques et
          sécurité. Sélectionnez ensuite Mot de passe. Entrer votre nouveau mot
          de passe et cliquez sur modifier le mot de passe.
        </Typography>

        <Typography variant="h3">
          Localiser et bloquer votre téléphone Samsung
        </Typography>
        <Typography>
          Comme pour le changement de mot de passe, vous vous connectez à votre
          compte Samsung. Allez ensuite dans paramètres, puis dans données
          biométriques et sécurité. Au niveau de traçage du mobile, activez
          votre verrouillage à distance.
        </Typography>
      </Stack>

      <Stack gap={3}>
        <Typography variant="h2">
          Les modèles de téléphone Samsung à localiser
        </Typography>
        <Typography>
          Les outils pour géolocaliser votre Smartphone Samsung sont compatibles
          avec les téléphones ci-après :
        </Typography>
        <Typography component="div">
          <ul>
            <li>Samsung Galaxy S20 (date de sortie, mars 2020)</li>
            <li>Samsung Galaxy S10 (date de sortie, mars 2019)</li>
            <li>Samsung Galaxy S9 (date de sortie, mars 2018)</li>
            <li>Samsung Galaxy S8 (date de sortie, mars 2017)</li>
            <li>
              Si vous disposez d'un Samsnug Galaxy S7 (date de sortie, mars
              2016), vous pouvez aussi profiter des services de localisation de
              ces outils.
            </li>
          </ul>
        </Typography>
      </Stack>
      <Stack sx={{ alignItems: "center" }}>
        <Button variant="contained" href="/" color="error">
          DEMARRER LA GEOLOCALISATION
        </Button>
      </Stack>
    </Stack>
  );
}

export default LocaliserUnSamsung;
